'use strict';

// Directive for Terminals
app.directive('terminalsTab', function() {
    return {
        restrict: 'E',
        templateUrl: 'app/terminals/list.html',
        controller: 'TerminalsController',
        link: function(scope, element, attrs) {
            scope.$on('tabSelected', function(event, tab) {
                if (tab === 'terminals') {
                    scope.getTerminals()
                }
            });
        }
    };
});

// Directive for Tablets
app.directive('tabletsTab', function() {
    return {
        restrict: 'E',
        templateUrl: 'app/tablets/list.html',
        controller: 'TabletsController',
        link: function(scope, element, attrs) {
            scope.$on('tabSelected', function(event, tab) {
                if (tab === 'tablets') {
                    scope.getTablets()
                }
            });
        }
    };
});

// Directive for WebApps
app.directive('webappsTab', function() {
    return {
        restrict: 'E',
        templateUrl: 'app/webapps/list.html',
        controller: 'WebAppsController',
        link: function(scope, element, attrs) {
            scope.$on('tabSelected', function(event, tab) {
                if (tab === 'webapps') {
                    scope.getWebapps()
                }
            });
        }
    };
});

// Main controller to handle tab selection
app.controller('SupportsController', function($scope, $timeout, Auth,User) {
    $scope.selectedTab = 0;
    $scope.setPermessions = (user=null)=>{
        $scope.hasWebAppsPermission = Auth.hasPermissions('WEBAPPS:GET', user);
        $scope.hasTerminalsPermission = Auth.hasPermissions('TERMINALS:GET', user);
        $scope.hasTabletsPermission = Auth.hasPermissions('TABLETS:GET', user);
    }
    $scope.tabChanged = function(tabIndex) {
        var tabNames = ['terminals', 'tablets', 'webapps'];
        var selectedTab = tabNames[tabIndex];
        $scope.$broadcast('tabSelected', selectedTab);
    };
    $timeout(function() {
        $scope.tabChanged($scope.selectedTab);
    },100);
    $scope.setPermessions()
});
